$black: #162133;
$lighterBlack: #1b1b2c;
$lightestBlack: #27273f;
$borderColor: rgba(0, 0, 0, 0.1);
$lighterBlackBorder: #3e3946;
$lightBlackBorder: rgba(64, 57, 70, 0.37);
$purple: #770fc8;
$purpleLight: #aa46f6;
$purpleMedium: #a25cd9;
$purpleDark: #4e07da;
$purpleDarkest: #0707b7;

$lightGreen: #0ab84a;
$green: #08a441;
$darkGreen: #068f38;

$lightRed: #f54242;
$red: #e34545;
$darkRed: #cc3c3c;

$green2: #00b589;
$green3: #19c959;
$pink: #f63f82;
$pink2: #c12860;
$white: #ffffff;
$lightGrey: #d4d4d4;
$darkBlue: #2867ce;
$blue: #19b4ad;
$lightBlue: #9cfaf5;
$darkGrey: #808080;
$mediumGrey: rgba(0, 0, 0, 0.03);
$mediumDarkGrey: rgba(0, 0, 0, 0.08);
$darkLightGrey: rgba(0, 0, 0, 0.135);
$darkestGrey: #5b5b68;
$paleGrey: #fbfafc;
$grey: rgba(255, 255, 255, 0.2);
$greyBackground: #b6c5de;
$lightWhite: rgba(255, 255, 255, 0.2);
$boxShadowColor: rgba(0, 0, 0, 0.15);
$bgColor: #eff4f8c7;
$linkActive: #1e2b40;
$darkText: #1b1b2c;
$lightText: #a09b91;
$mediumText: #504d49;
$yellowLight: #8ee46c;
$yellow: #ccaa6f;
$yellowDark: #cca561;
$yellowDarkest: #cc9d4b;
$warning: #eba02f
