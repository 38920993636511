@import "./colors";

body {
  margin: 0;
  padding: 0;
  background: $bgColor;
  color: $black;
}

.pageHeader {
  z-index: 1;
}

* {
  box-sizing: border-box;
  -ms-overflow-style: none;
  /* IE and Edge */

  scrollbar-width: thin;
  /* "auto" or "thin" */
  // scrollbar-color: white #888;
  /* scroll thumb and track */


  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0);
    /* color of the tracking area */
  }

  /* width */
  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }
}

form {
  width: 100%;
}

.yellow {
  color: $yellowDark;
}

.boxShadow {
  box-shadow: 9px 10px 17px $boxShadowColor;
}

.tag {
  max-width: 150px;
  padding: 8px 12px;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  border: 2px solid transparent;
  border-radius: 10px;
  box-shadow: 0px 3px 8px $boxShadowColor;

  &.green {
    border-color: $green;
    color: $green;
  }

  &.red {
    border-color: $red;
    color: $red;
  }
}

.pointer {
  cursor: pointer;
}

.green {
  color: $green;
}

.red {
  color: $red;
}

.warning {
  color: $warning;
}

.errorText {
  color: $red;
  font-size: 11px;
}

.inputBox {
  margin-bottom: 40px;
  position: relative;

  .errorText {
    position: absolute;
    bottom: -20px;
    left: 0;
  }
}

.lineInput {
  margin: 0;
  width: 100%;
  height: 56px;

  input {
    padding: 0;
  }

  >div {
    padding-right: 0;
    height: 56px;
  }

  svg {
    color: $yellowDark;
  }

  &+.errorText {
    margin-top: 5px;
  }
}

.scaled {
  transform: scale(1);

  &.s-80 {
    transform: scale(0.80);
  }
}

.outlinedInput {
  width: 100%;
  margin: 0;
  background: $bgColor;
  height: 40px;

  fieldset {
    border-color: transparent;
  }

  label {
    transform: translate(14px, 12px) scale(1);
    font-size: 14px;
  }

  label.MuiInputLabel-shrink {
    transform: translate(16px, -9px) scale(0.75);
  }

  input {
    padding: 0 0.5rem;
  }

  div {
    height: 40px;

    &:before,
    &:after {
      display: none !important;
    }
  }

  svg {
    color: $lightGrey;
  }

  p {
    display: none;
  }
}

.outlinedSelect {
  padding-top: 3vh;
  overflow: hidden;
}

.selectInput {
  p {
    margin-right: 12px;
  }

  fieldset {
    border-color: transparent;
  }

  .select {
    border-radius: 4px;
    height: 36px;
    min-width: 130px;

    .selectMenu {
      padding: 0;
    }

    >div {
      align-items: center;
      display: flex;
      height: 36px;
      opacity: 1;
      padding: 0 15px;
    }
  }
}

.customTextarea {
  width: 100%;
  margin: 0;
  background: $bgColor;
  min-height: 40px;

  fieldset {
    border-color: transparent;
  }

  label {
    transform: translate(14px, 12px) scale(1);
    font-size: 14px;
  }

  input {
    padding: 0 1rem;
  }

  div {
    min-height: 100px;

    &:before,
    &:after {
      display: none !important;
    }
  }

  p {
    display: none;
  }
}

.inputError {
  color: $red;
  font-size: 12px;
}

.pagination {
  margin-top: 20px;

  .paginationItem {
    background: $white;
    box-shadow: 0px 10px 10px $boxShadowColor;
    border: none;
    height: 40px;
    min-width: 40px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    &.selected {
      background: $yellowDark;
      color: $white;
    }
  }
}

.cursor {
  cursor: pointer;
}