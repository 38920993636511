@import "../../assets/styles/colors";

.customButton {
  min-height: 46px;
  min-width: 250px;

  .buttonIcon {
    height: 30px;
    margin-left: 10px;
  }

  &.fontSmall span {
    font-size: 9pt !important;
  }

  &.small {
    min-height: 23px;
    min-width: 125px;
  }

  span {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: capitalize;
  }

  .loader {
    color: $white;
    height: 20px;
    width: 20px;
  }

  &.submitButton {
    background: $yellowDark;
    color: $white;
    padding: 0 20px;
    border-radius: 30px;

    &:hover {
      background: $yellow;
    }
  }

  &.iconButton {


    &.MuiButton-outlined {
      background: transparent;
      color: $yellowDark !important;
      border: 2px solid $yellowDark;

      .icon {
        color: $yellowDark;
      }
    }

    background: $yellowDark;
    color: white;
    border-radius: 100%;
    min-width: 45px;
    max-width: 45px;
    min-height: 45px;

    &.small {
      min-width: 40px;
      max-width: 40px;
      min-height: 40px;

      .loader {
        height: 16px;
        width: 16px;
      }
    }

    &.delete {
      background-color: $darkRed;
      transition: 0.2s all;
    }

    &.delete:hover {
      background-color: lighten($color: $darkRed, $amount: 5);
      transition: 0.2s all;
    }

    &.verysmall {
      min-width: 28px;
      max-width: 28px;
      min-height: 28px;
      padding: 0;

      .loader {
        height: 16px;
        width: 16px;
      }

    }

    &.mx-2 {
      margin: 0 10px;
    }

    &.p-2 {
      padding: 8px 20px 8px 20px;
    }

    &.white {
      color: $white;
    }

    .icon {
      color: $white;
      width: 24px;
      height: 24px;
    }

    span {
      width: fit-content;
    }

    &:hover {
      background: $yellow;
    }
  }

  &.disabledButton,
  &[disabled] {
    opacity: 0.6;
    background: $lightGrey !important;
    color: $black !important;
    border-radius: 30px;

    &:hover {
      background: $lightGrey;
    }
  }

  // &[disabled] {
  // opacity: 0.6;
  // }

  &.textDefault {
    background: transparent;
    min-width: 120px;
  }

  &.textYellow {
    background: transparent;
    min-width: 120px;
    color: $yellowDark;

    .icon {
      font-size: 20px;
    }
  }

  &.full {
    width: 100%;
  }

  &.deleteButton {
    color: $white;
    background: $red;
    min-width: 0;
    border-radius: 30px;

    .loader {
      color: $white;
    }
  }

  &.successButton {
    color: $white;
    background: $green;
    min-width: 0;
    border-radius: 30px;

    .loader {
      color: $white;
    }
  }

  &.primaryButton {
    color: $white;
    background: $darkBlue;
    min-width: 0;
    border-radius: 30px;

    .loader {
      color: $white;
    }
  }

  &.cancelButton {
    color: $yellowDark;
    border: 2px solid $yellowDark;
    border-radius: 30px;

    .loader {
      color: $yellowDark;
    }
  }

  &.mb1 {
    margin-bottom: 10px;
  }

  &.mb2 {
    margin-bottom: 20px;
  }

  &.success {
    color: $white;
    background: $green;

    &:hover {
      background: $darkGreen;
    }
  }

  &.danger {
    color: $white;
    background: $red;

    &:hover {
      background: $darkRed;
    }
  }

  &.cancel {
    color: $white;
    background: $greyBackground;

    &:hover {
      background: $darkestGrey;
    }
  }

}


@media screen and (max-width: 768px) {
  .customButton.textYellow {
    min-width: 0px;
  }

  .customButton.iconButton {
    border-radius: 100%;
    min-width: 15px;
    max-width: 15px;
    min-height: 15px;

    &:not(.original-color) {
      background: $yellowDark;
      color: white;
    }
  }
}