.app {
  overflow: hidden; // causes multiple components to have important info and buttons be hidden
  overflow-y: auto; // allows app to scroll vertically
  height: 100vh;

  h1 {
    // color: red;
    text-transform: capitalize;
  }
}

.tabPanel {
  padding: 20px;
  width: 100%;
}

.MuiGrid-spacing-xs-3{
  margin: 0;
}

@media screen and (max-width: 768px){
  .MuiTypography-h3{
    font-size: 21px;
  }
  .MuiTypography-h4{
    font-size: 14px;
  }
  .MuiButton-text {
    padding: 3px 13px;
  }
}

.react-flow__attribution {
  display: none;
}